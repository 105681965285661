import { useTranslation } from "react-i18next"
import DOMPurify from 'dompurify'
import { useCallback } from "react"

const useCleanTranslation = () => {
    const { t } = useTranslation("dot")
    const ct = useCallback((key: string) => {
        return DOMPurify(window).sanitize(t(key), {
            ADD_ATTR: ["target", "document"]
        })
    }, [t])
    return { ct }
}

export default useCleanTranslation