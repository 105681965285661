import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import HorizontalRule from "../../../common/HorizontalRule";
import HeaderContext from "../../../context/HeaderContext";
import ArrowRight from "../../../svg/ArrowRight.js";
import MetaDecorator from "../../../utils/MetaDecorator";
import LoadingPage from "../../travel-registration/LoadingPage";
import {
  getCountryAliasFromUid,
  getCountryFlagFromCountryVB,
  getCountryNameFromUid,
} from "../../travel-registration/utils/countryInfo";
import CountryInfoHeader from "../utils/CountryInfoHeader";
import InfoCard from "../utils/InfoCard";

const BotschaftenKonsulate = () => {
  const { countryUid } = useParams();
  const { setHeader } = useContext(HeaderContext);
  const { t } = useTranslation();

  const [countryName] = useState(getCountryNameFromUid(countryUid));
  const [representations, setRepresentations] = useState(null);
  const [countryUidState] = useState(countryUid);
  const [countryAlias] = useState(getCountryAliasFromUid(countryUid));

  const [alertnateRepresentations, setAlternateRepresentations] = useState(null);

  useEffect(() => {
    setHeader((curr) => {
      return {
        ...curr,
        inUse: "content",
        title: t("pages.embassy.title"),
      };
    });

    if (representations == null) {
      const arr = localStorage.getItem("countriesVB") ? JSON.parse(localStorage.getItem("countriesVB")).countries : [];
      let data = [];
      let alternatesSearchIds = [];

      for (const element of arr) {
        if (element.countryUid == countryUid) {
          data = element.representations;

          if (element.alternativeOrgaUnitUids.length) {
            alternatesSearchIds = element.alternativeOrgaUnitUids;
          }
          break;
        }
      }

      if (alternatesSearchIds.length) {
        let obj = [];
        for (const element of arr) {
          if (!alternatesSearchIds.length) break;

          if (element.representations && element.representations.length) {
            for (const representations of element.representations) {
              if (!alternatesSearchIds.length) break;

              if (alternatesSearchIds.indexOf(representations.orgaUnitUid) >= 0) {
                delete alternatesSearchIds[alternatesSearchIds.indexOf(representations.orgaUnitUid)];

                if (obj.length == 0) {
                  obj.push({
                    countryName: element.country,
                    countryUid: element.countryUid,
                    countryAlias: element.countryAlias,
                    representations: [representations],
                  });
                } else {
                  let filteredObj = obj.filter((alt) => alt.countryName == element.country);

                  if (filteredObj.length) filteredObj.representations.push(representations);
                  else
                    obj.push({
                      countryName: element.country,
                      countryUid: element.countryUid,
                      countryAlias: element.countryAlias,
                      representations: [representations],
                    });
                }
              }
            }
          }
        }

        setAlternateRepresentations(obj);
      }

      setRepresentations(data);
    }
  }, []);

  if (representations == null) return <LoadingPage />;

  return (
    <section style={{ marginBottom: "56px" }}>
      <MetaDecorator title="pages.embassy.title" />
      {(representations?.length ?? 0) > 0 && (
        <>
          <CountryInfoHeader
            flag={getCountryFlagFromCountryVB(countryName)}
            Country={countryAlias.length > 0 ? t(countryAlias) + " (" + t(countryName) + ")" : countryName}
            className="country__flag-no-image"
          />

          {representations.map((rep, index) => {
            return (
              <div key={rep.locationUid}>
                <HorizontalRule />
                <Link
                  style={{ textDecoration: "none" }}
                  key=""
                  to={`/country-city/${countryUidState}/${rep.orgaUnitUid}`}
                >
                  <InfoCard key={rep.locationUid} type={rep.type} city={rep.city} ArrowRight={<ArrowRight />} />
                </Link>
              </div>
            );
          })}
        </>
      )}

      {alertnateRepresentations?.map((alt) => (
        <>
          <CountryInfoHeader
            flag={getCountryFlagFromCountryVB(alt.countryName)}
            Country={
              alt.countryAlias.length > 0 ? t(alt.countryAlias) + " (" + t(alt.countryName) + ")" : alt.countryName
            }
            className="country__flag-no-image"
          />

          {alt?.representations?.map((rep, index) => {
            return (
              <div key={rep.locationUid}>
                <HorizontalRule />
                <Link
                  style={{ textDecoration: "none" }}
                  key=""
                  to={`/country-city/${alt.countryUid}/${rep.orgaUnitUid}`}
                >
                  <InfoCard key={rep.locationUid} type={rep.type} city={rep.city} ArrowRight={<ArrowRight />} />
                </Link>
              </div>
            );
          })}
        </>
      ))}
    </section>
  );
};

export default BotschaftenKonsulate;