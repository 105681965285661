import React, { useContext } from 'react'
import { useForm } from '@mantine/hooks'
import GlobalContext from '../../../Contexts/GlobalContext'
import Translation from '../../Translation'
import dayjs from 'dayjs'

import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export const fixBirthday = (birthdate: string | Date) => {
    if (birthdate instanceof Date) return birthdate;

    const formats = ['DD/MM/YYYY', 'DD.MM.YYYY', 'DDMMYYYY'];

    for (const format of formats) {
        const parsedDate = dayjs(birthdate, format, true);
        if (parsedDate.isValid()) {
            const day = parsedDate.date();
            const month = parsedDate.month() + 1;
            const year = parsedDate.year();

            if (day >= 1 && day <= 31 && month >= 1 && month <= 12 && year >= 1000 && year <= 9999) {
                const formattedMonth = month.toString().padStart(2, '0');
                const formattedDay = day.toString().padStart(2, '0');
                
                const dateString = `${year}-${formattedMonth}-${formattedDay}T00:00:00.000Z`;
                
                const finalDate = dayjs(dateString).toDate();
                return finalDate;
            }
        }
    }

    return null;
};

const useFormOptions = () => {
    const { data } = useContext(GlobalContext)
    const { request: { document: documentId } } = data

    let lastName, firstName = ''
    let birthdate: Date

    if (data.request?.ownRequest === 'true') {
        lastName = data.user?.antragstellerLastName || ''
        firstName = data.user?.antragstellerFirstName || ''
        birthdate = fixBirthday(data.user?.antragstellerBirthdate) || new Date();
    } else {
        lastName = data.request?.lastName || ''
        firstName = data.request?.firstName || ''
        birthdate = data.request?.birthdate || ''
    }

    const errorMessages = {
        lastName: <Translation value={'form.step.personalData.lastName.error'} />,
        firstName: <Translation value={'form.step.personalData.firstName.error'} />,
        birthdate: <Translation value={'form.step.personalData.birthdate.error'} />,
        sex: <Translation value={'form.step.personalData.sex.error'} />,
        birthPlace: <Translation value={'form.step.personalData.birthPlace.error'} />,
        legalReasonText: <Translation value={'form.step.personalData.legalReasonText.error'} />,

        nationality: <Translation value={'form.step.personalData.nationality.error'} />,
        birthNation: <Translation value={'form.step.personalData.birthNation.error'} />,
        requestReasonOption: <Translation value={'form.step.personalData.requestReasonOption.error'} />,
        requestReason: <Translation value={'form.step.personalData.requestReason.error'} />,
        mainAddress: <Translation value={'form.step.personalData.mainAddress.error'} />,
        maritalStatus: <Translation value={'form.step.personalData.maritalStatus.error'} />,
        proofOwnership: <Translation value={'form.step.personalData.proofOwnership.error'} />,
        weddingOffice: <Translation value={'form.step.personalData.weddingOffice.error'} />,
        weddingLocation: <Translation value={'form.step.personalData.weddingLocation.error'} />,
        weddingDate: <Translation value={'form.step.personalData.weddingDate.error'} />,
        fatherFirstName: <Translation value={'form.step.personalData.fatherFirstName.error'} />,
        fatherLastName: <Translation value={'form.step.personalData.fatherLastName.error'} />,
        fatherBirthdate: <Translation value={'form.step.personalData.fatherBirthdate.error'} />,
        fatherBirthPlace: <Translation value={'form.step.personalData.fatherBirthPlace.error'} />,
        fatherNationality: <Translation value={'form.step.personalData.fatherNationality.error'} />,
        fatherBirthName: <Translation value={'form.step.personalData.fatherBirthName.error'} />,
        motherFirstName: <Translation value={'form.step.personalData.motherFirstName.error'} />,
        motherLastName: <Translation value={'form.step.personalData.motherLastName.error'} />,
        motherBirthdate: <Translation value={'form.step.personalData.motherBirthdate.error'} />,
        motherNationality: <Translation value={'form.step.personalData.motherNationality.error'} />,
        motherBirthPlace: <Translation value={'form.step.personalData.motherBirthPlace.error'} />,
        motherBirthName: <Translation value={'form.step.personalData.motherBirthName.error'} />,
        motherAddressBirth: <Translation value={'form.step.personalData.motherAddressBirth.error'} />,
        parentWeddingLocation: <Translation value={'form.step.personalData.parentWeddingLocation.error'} />,
        parentWeddingDate: <Translation value={'form.step.personalData.parentWeddingDate.error'} />,
        abroadAddress: <Translation value={'form.step.personalData.abroadAddress.error'} />,
        deathLocation: <Translation value={'form.step.personalData.deathLocation.error'} />,
        deathDate: <Translation value={'form.step.personalData.deathDate.error'} />,
        partner1FirstName: <Translation value={'form.step.personalData.partner1FirstName.error'} />,
        partner1LastName: <Translation value={'form.step.personalData.partner1LastName.error'} />,
        partner1PreviousLastName: <Translation value={'form.step.personalData.partner1PreviousLastName.error'} />,
        partner1PreviousNames: <Translation value={'form.step.personalData.partner1PreviousNames.error'} />,
        partner1OtherNames: <Translation value={'form.step.personalData.partner1OtherNames.error'} />,
        partner1Sex: <Translation value={'form.step.personalData.partner1GenderDropdown.error'} />,
        partner1Birthdate: <Translation value={'form.step.personalData.partner1Birthdate.error'} />,
        partner1Nationality: <Translation value={'form.step.personalData.partner1Nationality.error'} />,
        partner1BirthPlace: <Translation value={'form.step.personalData.partner1BirthPlace.error'} />,
        partner2FirstName: <Translation value={'form.step.personalData.partner2FirstName.error'} />,
        partner2LastName: <Translation value={'form.step.personalData.partner2LastName.error'} />,
        partner2PreviousLastName: <Translation value={'form.step.personalData.partner2PreviousLastName.error'} />,
        partner2PreviousNames: <Translation value={'form.step.personalData.partner2PreviousNames.error'} />,
        partner2OtherNames: <Translation value={'form.step.personalData.partner2OtherNames.error'} />,
        partner2Sex: <Translation value={'form.step.personalData.partner2GenderDropdown.error'} />,
        partner2Birthdate: <Translation value={'form.step.personalData.partner2Birthdate.error'} />,
        partner2BirthPlace: <Translation value={'form.step.personalData.partner2BirthPlace.error'} />,
        partnershipLocation: <Translation value={'form.step.personalData.partnershipLocation.error'} />,
        partnershipDate: <Translation value={'form.step.personalData.partnershipDate.error'} />,
    }

    return useForm({
        initialValues: {
            ownRequest: data.request?.ownRequest || 'true',
            lastName,
            firstName,
            birthdate,
            title: data.request?.title || '',
            titleAfter: data.request?.titleAfter || '',
            sex: data.request?.sex || '',
            birthPlace: data.request?.birthPlace || '',
            legalReasonText: data.request?.legalReasonText || '',

            // extra felder je nach Antrag
            pastLastNames: data.request?.pastLastNames || '',
            nationality: data.request?.nationality || '',
            birthNation: data.request?.birthNation || '',
            requestReasonOption: data.request?.requestReasonOption || '',
            requestReason: data.request?.requestReason || '',
            mainAddress: data.request?.mainAddress || '',
            maritalStatus: data.request?.maritalStatus || '',
            proofOwnership: data.request?.proofOwnership || '',
            weddingOffice: data.request?.weddingOffice || '',
            weddingLocation: data.request?.weddingLocation || '',
            weddingDate: data.request?.weddingDate ?? null,
            fatherFirstName: data.request?.fatherFirstName || '',
            fatherLastName: data.request?.fatherLastName || '',
            fatherBirthdate: data.request?.fatherBirthdate ?? null,
            fatherBirthPlace: data.request?.fatherBirthPlace || '',
            fatherNationality: data.request?.fatherNationality || '',
            fatherBirthName: data.request?.fatherBirthName || '',
            motherFirstName: data.request?.motherFirstName || '',
            motherLastName: data.request?.motherLastName || '',
            motherBirthdate: data.request?.motherBirthdate ?? null,
            motherNationality: data.request?.motherNationality || '',
            motherBirthPlace: data.request?.motherBirthPlace || '',
            motherBirthName: data.request?.motherBirthName || '',
            motherAddressBirth: data.request?.motherAddressBirth || '',
            parentWeddingLocation: data.request?.parentWeddingLocation || '',
            parentWeddingDate: data.request?.parentWeddingDate ?? null,
            abroadAddress: data.request?.abroadAddress || '',
            deathLocation: data.request?.deathLocation || '',
            deathDate: data.request?.deathDate ?? null,
            partner1FirstName: data.request?.partner1FirstName || '',
            partner1LastName: data.request?.partner1LastName || '',
            partner1PreviousLastName: data.request?.partner1PreviousLastName || '',
            partner1PreviousNames: data.request?.partner1PreviousNames || '',
            partner1OtherNames: data.request?.partner1OtherNames || '',
            partner1Sex: data.request?.partner1Sex || '',
            partner1Birthdate: data.request?.partner1Birthdate ?? null,
            partner1Nationality: data.request?.partner1Nationality || '',
            partner1BirthPlace: data.request?.partner1BirthPlace || '',
            partner2FirstName: data.request?.partner2FirstName || '',
            partner2LastName: data.request?.partner2LastName || '',
            partner2PreviousLastName: data.request?.partner2PreviousLastName || '',
            partner2PreviousNames: data.request?.partner2PreviousNames || '',
            partner2OtherNames: data.request?.partner2OtherNames || '',
            partner2Sex: data.request?.partner2Sex || '',
            partner2Birthdate: data.request?.partner2Birthdate ?? null,
            partner2BirthPlace: data.request?.partner2BirthPlace || '',
            partnershipLocation: data.request?.partnershipLocation || '',
            partnershipDate: data.request?.partnershipDate ?? null,
        },
        validationRules: {
            lastName: (value: string) => !!value,
            firstName: (value: string) => !!value,
            birthdate: (value: Date) => !!value,
            sex: (value: string) => !!value,
            birthPlace: (value: string) => !!value && value.length <= 50,
            legalReasonText: (value: string, values) => (values?.ownRequest === 'true') || (!!value && value.length < 150),

            // neue
            nationality: (value: string) => (documentId !== "1") || !!value, // 1
            birthNation: (value: string) => (documentId !== "1") || !!value, // 1
            requestReasonOption: (value: string) => (documentId !== "1") || !!value, // 1
            requestReason: (value: string, values) => documentId !== "1" || (documentId === "1" && values?.requestReasonOption !== "V") || (documentId === "1" && values?.requestReasonOption === "V"), // 1
            mainAddress: (value: string) => (documentId !== "9") || !!value, // 9
            maritalStatus: (value: string, values) => (documentId === "9" && !!value) || documentId !== "9", // 9
            proofOwnership: (value: string) => (documentId !== "9") || !!value, // 9
            weddingOffice: (value: string) => (documentId !== "3") || !!value, // 3
            weddingLocation: (value: string) => (documentId !== "3") || !!value, // 3
            weddingDate: (value: string) => (documentId !== "3") || !!value, // 3
            fatherFirstName: (value: string) => (!["1", "2", "9"].includes(documentId)) || !!value, // 1, 2, 9
            fatherLastName: (value: string) => (!["2", "9"].includes(documentId)) || !!value, // 2, 9
            fatherBirthdate: (value: string) => (!["2", "9"].includes(documentId)) || !!value, // 2, 9
            fatherBirthPlace: (value: string) => (!["2", "9"].includes(documentId)) || !!value, // 2, 9
            fatherNationality: (value: string) => (documentId !== "9") || !!value, // 9
            fatherBirthName: (value: string) => (documentId !== "2") || !!value, // 2
            motherFirstName: (value: string) => (!["1", "2", "9"].includes(documentId)) || !!value, // 1, 2, 9
            motherLastName: (value: string) => (!["2", "9"].includes(documentId)) || !!value, // 2, 9
            motherBirthdate: (value: string) => (!["2", "9"].includes(documentId)) || !!value, // 2, 9
            motherNationality: (value: string) => (documentId !== "9") || !!value, // 9
            motherBirthPlace: (value: string) => (!["2", "9"].includes(documentId)) || !!value, // 2, 9
            motherBirthName: (value: string) => (documentId !== "2") || !!value, // 2
            motherAddressBirth: (value: string) => (documentId !== "9") || !!value, // 9
            parentWeddingLocation: (value: string, values) => (documentId !== "9") || !(!!values!.parentWeddingDate && !value), // 9
            parentWeddingDate: (value: string, values) => (documentId !== "9") || !(!!values!.parentWeddingLocation && !value), // 9
            abroadAddress: (value: string) => (!["2", "3", "4", "10"].includes(documentId)) || !!value, // 2, 3, 4, 10
            deathLocation: (value: string) => (documentId !== "4") || !!value, // 4
            deathDate: (value: string) => (documentId !== "4") || !!value, // 4
            partner1FirstName: (value: string, values) => !["3", "4", "9", "10"].includes(documentId) || !!value, // 3, 4, 9, 10
            partner1LastName: (value: string, values) => !["3", "4", "9", "10"].includes(documentId) || !!value, // 3, 4, 9, 10
            partner1PreviousLastName: (value: string) => (documentId !== "10") || !!value, // 10
            partner1PreviousNames: (value: string) => (!["3"].includes(documentId)) || !!value, // 3, 4
            partner1OtherNames: (value: string) => (documentId !== "10") || !!value, // 10
            partner1Sex: (value: string) => (!["4", "10"].includes(documentId)) || !!value, // 4, 10
            partner1Birthdate: (value: string, values) => !["3", "4", "9", "10"].includes(documentId) || !!value, // 3, 4, 9, 10
            partner1Nationality: (value: string, values) => documentId !== "9" || !!value, // 9
            partner1BirthPlace: (value: string, values) => !["3", "4", "9", "10"].includes(documentId) || !!value, // 3, 4, 9, 10
            partner2FirstName: (value: string) => (!["3", "10"].includes(documentId)) || !!value, // 3, 10
            partner2LastName: (value: string) => (!["3", "10"].includes(documentId)) || !!value, // 3, 10
            partner2PreviousLastName: (value: string) => (documentId !== "10") || !!value, // 10
            partner2PreviousNames: (value: string) => (documentId !== "3") || !!value, // 3
            partner2OtherNames: (value: string) => (documentId !== "10") || !!value, // 10
            partner2Sex: (value: string) => (documentId !== "10") || !!value, // 10
            partner2Birthdate: (value: string) => (!["3", "10"].includes(documentId)) || !!value, // 3, 10
            partner2BirthPlace: (value: string) => (!["3", "10"].includes(documentId)) || !!value, // 3, 10
            partnershipLocation: (value: string) => (documentId !== "10") || !!value, // 10
            partnershipDate: (value: string) => (documentId !== "10") || !!value, // 10
        },
        errorMessages
    })
}

export default useFormOptions
